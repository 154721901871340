import React from 'react'
import Slider from 'react-slick'
import useAppContext from '../hooks/useAppContext'
import SectionTitle from './SectionTitle'
const Testimonials = () => {
  const { classicHeader, darkTheme, developerInfo } = useAppContext()
  const {
    testimonials: { reviews }
  } = developerInfo
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  }
  return (
    <section
      id='testimonial'
      className={'section ' + (darkTheme ? 'bg-dark-1' : '')}
    >
      <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
        {/* Heading */}
        <SectionTitle title='Client Speak' bgTitle='Testimonial' />
        {/* Heading end*/}
        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((reviewItem, index) => (
              <div className='item' key={index}>
                <div
                  className={
                    ' rounded p-5 ' + (darkTheme ? 'bg-dark' : 'bg-light')
                  }
                >
                  <div className='d-flex align-items-center mt-auto mb-4'>
                    {reviewItem.src?.length > 0 && (
                      <img
                        className='img-fluid rounded-circle border d-inline-block w-auto'
                        src={reviewItem.src}
                        alt={`testimonial image ${index}`}
                        style={{
                          width: '108px',
                          height: '108px',
                          maxHeight: '108px',
                          maxWidth: '108px'
                        }}
                      />
                    )}
                    <p className='ms-3 mb-0'>
                      <strong
                        className={
                          'd-block fw-600 ' +
                          (darkTheme ? 'text-white' : 'text-dark')
                        }
                      >
                        {reviewItem.name}
                      </strong>
                      <span className='text-muted fw-500'>
                        {reviewItem.position}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      ' fw-500 mb-4 ' + (darkTheme ? 'text-white' : 'text-dark')
                    }
                  >
                    “{reviewItem.desc}”
                  </p>
                  <span className='text-2'>
                    {[...Array(reviewItem.rating)].map((value, i) => (
                      <i className='fas fa-star text-warning' key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  )
}

export default Testimonials
