import React from 'react'
import Typewriter from 'typewriter-effect'
import useAppContext from '../hooks/useAppContext'

const Home = () => {
  const { handleNavClick, developerInfo } = useAppContext()
  const {
    home: { img, video, randomTitles, address }
  } = developerInfo
  return (
    <section id='home'>
      <div className='hero-wrap'>
        <div className='hero-mask opacity-8 bg-dark' />
        {/* ---------------image background------------------ */}
        {img?.length > 0 && (
          <div
            className='hero-bg parallax'
            style={{ backgroundImage: `url(${img})` }}
          ></div>
        )}
        {/* -------------------video background---------------------- */}
        {video?.length > 0 && !img?.length > 0 && (
          <div className='player hero-bg parallax'>
            <video
              src={video}
              autoPlay
              muted
              loop
              style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            ></video>
          </div>
        )}
        <div className='hero-content section d-flex min-vh-100'>
          <div className='container my-auto'>
            <div className='row'>
              <div className='col-12 text-center'>
                <p className='text-7 fw-500 text-white mb-2 mb-md-3'>Welcome</p>
                <h2 className='text-16 fw-600 text-white mb-2 mb-md-3'>
                  <Typewriter
                    options={{
                      strings: randomTitles,
                      autoStart: true,
                      loop: true
                    }}
                  />
                </h2>
                {address?.length > 0 && (
                  <p className='text-5 text-light mb-4'>{address}</p>
                )}
                <a
                  href='#contact'
                  className='btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2'
                  onClick={(e) => {
                    e.preventDefault()
                    handleNavClick('contact')
                  }}
                >
                  Hire Me
                </a>
              </div>
            </div>
          </div>
          <a
            href='#about'
            className='scroll-down-arrow text-white smooth-scroll'
            onClick={(e) => {
              e.preventDefault()
              handleNavClick('about')
            }}
          >
            <span className='animated'>
              <i className='fa fa-chevron-down' />
            </span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Home
