import React, { useMemo } from 'react'
import useAppContext from '../hooks/useAppContext'
import SectionTitle from './SectionTitle'
const About = () => {
  const { classicHeader, darkTheme, developerInfo } = useAppContext()
  const {
    about: {
      fullName,
      tagLine,
      line1,
      line2,
      email,
      age,
      address,
      cvLink,
      yearsOfExperience,
      numberOfClients,
      numberOfProjects,
      numberOfAwards
    }
  } = developerInfo

  const aboutExpItems = useMemo(() => {
    const result = []
    if (yearsOfExperience) {
      result.push({ title: 'Years Experiance', value: yearsOfExperience })
    }
    if (numberOfClients) {
      result.push({ title: 'Happy Clients', value: numberOfClients })
    }
    if (numberOfProjects) {
      result.push({ title: 'Projects Done', value: numberOfProjects })
    }
    if (numberOfAwards) {
      result.push({ title: 'Get Awards', value: numberOfAwards })
    }

    return result
  }, [yearsOfExperience, numberOfClients, numberOfProjects, numberOfAwards])

  return (
    <section id='about' className={'section ' + (darkTheme ? 'bg-dark-1' : '')}>
      <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
        {/* Heading */}
        <SectionTitle title='Know Me More' bgTitle='About Me' />
        {/* Heading end*/}
        <div className='row gy-5'>
          {/* About me content start */}
          <div className='col-lg-7 col-xl-8 text-center text-lg-start'>
            <h2
              className={
                'text-7 fw-600 mb-3 ' + (darkTheme ? 'text-white' : '')
              }
            >
              I'm <span className='text-primary'>{fullName},</span>
              {` ${tagLine}`}
            </h2>
            {line1?.length > 0 && (
              <p className={darkTheme ? 'text-white-50' : ''}>{line1}</p>
            )}
            {line2?.length > 0 && (
              <p className={darkTheme ? 'text-white-50' : ''}>{line2}</p>
            )}
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className='col-lg-5 col-xl-4'>
            <div className='ps-lg-4'>
              <ul
                className={
                  'list-style-2 ' +
                  (darkTheme ? 'list-style-light text-light' : '')
                }
              >
                {fullName?.length > 0 && (
                  <li>
                    <span className='fw-600 me-2'>Name:</span>
                    {fullName}
                  </li>
                )}
                {email?.length > 0 && (
                  <li>
                    <span className='fw-600 me-2'>Email:</span>
                    <a href={'mailto:' + email}>{email}</a>
                  </li>
                )}
                {age?.length > 0 && (
                  <li>
                    <span className='fw-600 me-2'>Age:</span>
                    {age}
                  </li>
                )}
                {address?.length > 0 && (
                  <li className='border-0'>
                    <span className='fw-600 me-2'>From:</span>
                    {address}
                  </li>
                )}
              </ul>
              {cvLink?.length > 0 && (
                <a
                  href={cvLink}
                  download
                  className='btn btn-primary rounded-pill'
                >
                  Download CV
                </a>
              )}
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            'brands-grid separator-border mt-5 ' +
            (darkTheme ? 'separator-border-light' : '')
          }
        >
          <div className='row'>
            {aboutExpItems?.map((item) => {
              return (
                <div className='col-6 col-md-3'>
                  <div className='featured-box text-center'>
                    <h4
                      className={
                        'text-12  mb-0 ' +
                        (darkTheme ? 'text-white-50' : 'text-muted')
                      }
                    >
                      <span>{item.value}</span>+
                    </h4>
                    <p className={'mb-0 ' + (darkTheme ? 'text-light' : '')}>
                      {item.title}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  )
}

export default About
