import React from 'react'
import { Tooltip } from '.'
import useAppContext from '../hooks/useAppContext'

const SocialIcons = () => {
  const { darkTheme, developerInfo } = useAppContext()
  const { mySocialAccounts } = developerInfo
  return (
    <ul
      className={
        'social-icons justify-content-center justify-content-md-start ' +
        (darkTheme ? 'social-icons-muted' : '')
      }
    >
      {mySocialAccounts?.map(({ icn, url }) => (
        <li className={`social-icons-${icn.toLowerCase()}`}>
          <Tooltip text={icn} placement='top'>
            <a href={url} target='_blank' rel='noopener noreferrer'>
              <i className={`fab fa-${icn.toLowerCase()}`} />
            </a>
          </Tooltip>
        </li>
      ))}
    </ul>
  )
}

export default SocialIcons
