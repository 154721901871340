import React from 'react'
import useAppContext from '../hooks/useAppContext'

const Disclaimer = () => {
  const { darkTheme, developerInfo } = useAppContext()
  const {
    about: { fullName: name },
    contact: { email }
  } = developerInfo

  return (
    <div
      id='disclaimer'
      className='modal fade'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
      >
        <div
          className={
            'modal-content ' + (darkTheme ? 'bg-dark-2 text-light' : '')
          }
        >
          <div className='modal-header'>
            <h5 className={'modal-title ' + (darkTheme ? 'text-white' : '')}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type='button'
              className={'btn-close ' + (darkTheme ? 'btn-close-white' : '')}
              data-bs-dismiss='modal'
              aria-label='Close'
            />
          </div>
          <div className='modal-body p-4'>
            <p>
              All content, including text, images, graphics, and multimedia
              elements, on this personal portfolio website is the intellectual
              property of {name}. It is protected by copyright laws and may not
              be reproduced, distributed, transmitted, displayed, or used
              without prior written permission.
            </p>
            <p>
              The information provided on this personal portfolio website is for
              general informational purposes only. {name} makes no
              representations or warranties of any kind, express or implied,
              about the completeness, accuracy, reliability, suitability, or
              availability of the information, products, services, or related
              graphics contained on the website. Any reliance you place on such
              information is therefore strictly at your own risk.
            </p>
            <ul className='lh-lg'>
              <li>
                The content on this personal portfolio website may contain
                external links to other websites or resources. These links are
                provided for convenience and do not signify endorsement or
                responsibility for the content, accuracy, or privacy practices
                of those external websites.
              </li>
              <li>
                {name} reserves the right to modify, update, or remove any
                content on this personal portfolio website without prior notice.
              </li>
              <li>
                By accessing and using this personal portfolio website, you
                agree to the terms of the Copyright and Disclaimer stated above.
              </li>
              <li>
                If you have any questions or concerns regarding the Copyright
                and Disclaimer, please contact {name} at {email}.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer
