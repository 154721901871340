import React, { useMemo, useState } from 'react'
import { Link } from 'react-scroll'
import SocialIcons from './SocialIcons'
import useAppContext from '../hooks/useAppContext'

const Header = ({ homeRef }) => {
  const { darkTheme, developerInfo } = useAppContext()
  const [isNavModalClose, setIsNavModalClose] = useState(true)
  const {
    header: { profileImg, name }
  } = developerInfo

  const menuItems = useMemo(() => {
    return [
      {
        target: homeRef,
        title: 'Home',
        to: 'home'
      },
      {
        title: 'About Me',
        to: 'about'
      },
      {
        title: 'What I Do',
        to: 'services'
      },
      {
        title: 'Resume',
        to: 'resume'
      },
      {
        title: 'Portfolio',
        to: 'portfolio'
      },
      {
        title: 'Testimonial',
        to: 'testimonial'
      },
      {
        title: 'Contact',
        to: 'contact'
      }
    ]
  }, [homeRef])

  return (
    <header id='header' className='sticky-top'>
      {/* Navbar */}
      <nav className='primary-menu navbar navbar-expand-lg navbar-dark bg-dark border-bottom-0'>
        <div className='container-fluid position-relative h-100 flex-lg-column ps-3 px-lg-3 pt-lg-3 pb-lg-2'>
          {/* Logo */}
          <Link
            smooth
            duration={500}
            style={{ cursor: 'pointer' }}
            to='home'
            className='mb-lg-auto mt-lg-4'
            onClick={(e) => {
              e.preventDefault()
              setIsNavModalClose(true)
            }}
          >
            {profileImg?.length > 0 && name?.length > 0 && (
              <span className='bg-dark-2 rounded-pill p-2 mb-lg-1 d-none d-lg-inline-block'>
                <img
                  className='img-fluid rounded-pill d-block'
                  src={profileImg}
                  title={name}
                  alt='profile'
                />
              </span>
            )}
            {name?.length > 0 && (
              <h1 className='text-5 text-white text-center mb-0 d-lg-block'>
                {name}
              </h1>
            )}
          </Link>
          {/* Logo End */}
          <div
            id='header-nav'
            className={
              isNavModalClose
                ? 'collapse navbar-collapse w-100 my-lg-auto '
                : 'show navbar-collapse w-100 my-lg-auto'
            }
          >
            <ul className='navbar-nav text-lg-center my-lg-auto py-lg-3'>
              {menuItems?.map((item) => {
                return (
                  <li className='nav-item'>
                    <Link
                      target={item.target}
                      className='nav-link '
                      smooth
                      duration={500}
                      style={{ cursor: 'pointer' }}
                      activeClass='active'
                      spy
                      to={item.to}
                      onClick={(e) => {
                        e.preventDefault()
                        setIsNavModalClose(true)
                      }}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <SocialIcons darkTheme={darkTheme} />
          <button
            onClick={(e) => {
              setIsNavModalClose(!isNavModalClose)
            }}
            className={
              isNavModalClose ? 'navbar-toggler' : 'navbar-toggler show'
            }
            id='navbar-toggler'
            type='button'
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  )
}

export default Header
