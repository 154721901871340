import React from 'react'
import useAppContext from '../hooks/useAppContext'

const ShareLinks = () => {
  const { darkTheme, developerInfo } = useAppContext()
  const { shareLinks } = developerInfo
  return (
    <ul className={'social-icons' + (darkTheme ? ' social-icons-muted' : '')}>
      {shareLinks?.map(({ icn, url }) => (
        <li className={`social-icons-${icn.toLowerCase()}`}>
          <a
            data-toggle='tooltip'
            href={url}
            target='_blank'
            rel='noopener noreferrer'
            data-original-title={icn}
          >
            <i className={`fab fa-${icn.toLowerCase()}`} />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default ShareLinks
