import React from 'react'
import useAppContext from '../hooks/useAppContext'
import SectionTitle from './SectionTitle'
const Resume = () => {
  const { classicHeader, darkTheme, developerInfo } = useAppContext()
  const {
    resume: { educationDetails, experienceDetails, skills, about },
    about: { cvLink }
  } = developerInfo
  return (
    <section
      id='resume'
      className={'section ' + (darkTheme ? 'bg-dark-1' : '')}
    >
      <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
        {/* Heading */}
        <SectionTitle title='Resume' bgTitle='Summary' />
        {/* Heading end*/}
        <div className='row gx-5'>
          {/* My Education */}
          {educationDetails.length > 0 && (
            <div className='col-md-6'>
              <h2
                className={
                  'text-6 fw-600 mb-4 ' + (darkTheme ? 'text-white' : '')
                }
              >
                My Education
              </h2>
              {educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    'bg-white  rounded p-4 mb-4 ' +
                    (darkTheme ? 'bg-dark' : 'bg-white border')
                  }
                >
                  {value.yearRange?.length > 0 && (
                    <p className='badge bg-primary text-2 fw-400'>
                      {value.yearRange}
                    </p>
                  )}
                  {value.title?.length > 0 && (
                    <h3 className={'text-5 ' + (darkTheme ? 'text-white' : '')}>
                      {value.title}
                    </h3>
                  )}
                  {value.place?.length > 0 && (
                    <p className={darkTheme ? 'text-primary' : 'text-danger'}>
                      {value.place}
                    </p>
                  )}
                  {value.desc?.length > 0 && (
                    <p className={'mb-0 ' + (darkTheme ? 'text-white-50' : '')}>
                      {value.desc}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
          {/* My Experience */}
          {experienceDetails?.length > 0 && (
            <div className='col-md-6'>
              <h2
                className={
                  'text-6 fw-600 mb-4 ' + (darkTheme ? 'text-white' : '')
                }
              >
                My Experience
              </h2>
              {experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    'bg-white  rounded p-4 mb-4 ' +
                    (darkTheme ? 'bg-dark' : 'bg-white border')
                  }
                >
                  <p className='badge bg-primary text-2 fw-400'>
                    {value.yearRange}
                  </p>
                  <h3 className={'text-5 ' + (darkTheme ? 'text-white' : '')}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? 'text-primary' : 'text-danger'}>
                    {value.place}
                  </p>
                  <p className={'mb-0 ' + (darkTheme ? 'text-white-50' : '')}>
                    {value.desc}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* My Skills */}
        {skills?.length > 0 && (
          <>
            <h2
              className={
                'text-6 fw-600 mt-4 mb-4 ' + (darkTheme ? 'text-white' : '')
              }
            >
              My Skills
            </h2>
            <div className='row gx-5'>
              {skills.map((skill, index) => (
                <div key={index} className='col-md-6'>
                  <p
                    className={
                      ' fw-500 text-start mb-2 ' +
                      (darkTheme ? 'text-light' : 'text-dark')
                    }
                  >
                    {skill.name}{' '}
                    <span className='float-end'>{skill.percent}%</span>
                  </p>
                  <div
                    className={
                      'progress progress-sm mb-4 ' +
                      (darkTheme ? 'bg-dark' : '')
                    }
                  >
                    <div
                      className='progress-bar'
                      role='progressbar'
                      style={{ width: skill.percent + '%' }}
                      aria-valuenow={skill.percent}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {cvLink.length > 0 && (
          <div className='text-center mt-5'>
            <a
              className='btn btn-outline-secondary rounded-pill shadow-none'
              href={cvLink}
              download
            >
              Download CV
              <span className='ms-1'>
                <i className='fas fa-download' />
              </span>
            </a>
          </div>
        )}
      </div>
    </section>
  )
}

export default Resume
