import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import ShareLinks from './ShareLinks'
import useAppContext from '../hooks/useAppContext'

const ProjectDetailsModal = ({ getCombinedPath, projectDetails }) => {
  const { darkTheme } = useAppContext()
  const sliderRef = useRef()

  const {
    title,
    projectInfo,
    client,
    date,
    technologies,
    industry,
    url,
    thumbImage,
    sliderImages
  } = projectDetails ?? {}

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0)
    }
  }, [projectDetails])

  if (Object.keys(projectDetails ?? {})?.length === 0) {
    return null
  }
  return (
    <>
      <div
        className='modal fade bg-dark-1'
        id='exampleModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-xl'>
          <div
            className={
              'modal-content ' + (darkTheme ? 'bg-dark-2 text-light' : '')
            }
          >
            <div className='modal-body'>
              <button
                type='button'
                className={'btn-close ' + (darkTheme ? 'btn-close-white' : '')}
                data-bs-dismiss='modal'
                aria-label='Close'
              />
              <div
                className={
                  'container ajax-container ' +
                  (darkTheme ? 'bg-dark-2 text-light' : '')
                }
              >
                {title?.length > 0 && (
                  <h2
                    className={
                      'text-6 font-weight-600 text-center mb-4' +
                      (darkTheme ? ' text-white' : '')
                    }
                  >
                    {title}
                  </h2>
                )}
                <div className='row g-4'>
                  <div className='col-md-7'>
                    <Slider {...settings} ref={sliderRef}>
                      {[thumbImage, ...sliderImages]?.map((image, index) => (
                        <div className='item' key={index}>
                          <img
                            className='img-fluid'
                            alt={`project image ${index}`}
                            src={getCombinedPath(image)}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className='col-md-5'>
                    {projectInfo?.length > 0 && (
                      <>
                        <h4
                          className={
                            'text-4 font-weight-600' +
                            (darkTheme ? ' text-white' : '')
                          }
                        >
                          Project Info:
                        </h4>
                        <p>{projectInfo}</p>
                      </>
                    )}
                    <h4
                      className={
                        'text-4 font-weight-600 mt-4' +
                        (darkTheme ? ' text-white' : '')
                      }
                    >
                      Project Details:
                    </h4>
                    <ul
                      className={
                        'list-style-2 ' + (darkTheme ? 'list-style-light' : '')
                      }
                    >
                      {client?.length > 0 && (
                        <li>
                          <span
                            className={
                              'text-dark font-weight-600 me-2' +
                              (darkTheme ? ' text-white' : '')
                            }
                          >
                            Client:
                          </span>
                          {client}
                        </li>
                      )}
                      {technologies?.length > 0 && (
                        <li>
                          <span
                            className={
                              'text-dark font-weight-600 me-2' +
                              (darkTheme ? ' text-white' : '')
                            }
                          >
                            Technologies:
                          </span>
                          {technologies}
                        </li>
                      )}
                      {industry?.length > 0 && (
                        <li>
                          <span
                            className={
                              'text-dark font-weight-600 me-2' +
                              (darkTheme ? ' text-white' : '')
                            }
                          >
                            Industry:
                          </span>
                          {industry}
                        </li>
                      )}
                      {date?.length > 0 && (
                        <li>
                          <span
                            className={
                              'text-dark font-weight-600 me-2' +
                              (darkTheme ? ' text-white' : '')
                            }
                          >
                            Date:
                          </span>
                          {date}
                        </li>
                      )}
                      {Object.keys(url ?? {}).length > 0 && (
                        <li>
                          <span
                            className={
                              'text-dark font-weight-600 me-2' +
                              (darkTheme ? ' text-white' : '')
                            }
                          >
                            URL:
                          </span>
                          <a
                            href={url?.link}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {url?.name}
                          </a>
                        </li>
                      )}
                    </ul>
                    <div className='row no-gutters align-items-center'>
                      <div
                        className={
                          'col-auto text-dark font-weight-600' +
                          (darkTheme ? ' text-white' : '')
                        }
                      >
                        Share:
                      </div>
                      <div className='col-auto'>
                        <ShareLinks darkTheme={darkTheme} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectDetailsModal
