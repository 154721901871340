import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react'

const initialValue = {
  darkTheme: false,
  classicHeader: false,
  isLoading: true,
  developerInfo: null // possible value will be object after API call
}
export const AppContext = createContext(initialValue)

const AppContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState(initialValue)

  const handleNavClick = useCallback(
    (section) => {
      document.getElementById(section).scrollIntoView({ behavior: 'smooth' })
    },
    [document]
  )

  const toggleTheme = useCallback((newValue) => {
    setContextValue((prev) => ({
      ...prev,
      darkTheme: newValue ?? !prev.darkTheme
    }))
  }, [])

  const toggleHeader = useCallback((newValue) => {
    setContextValue((prev) => ({
      ...prev,
      classicHeader: newValue ?? !prev.classicHeader
    }))
  }, [])

  const toggleIsLoading = useCallback((value) => {
    setContextValue((prev) => ({
      ...prev,
      isLoading: value
    }))
  }, [])

  const updateUserInfo = useCallback((data) => {
    setContextValue((prev) => ({
      ...prev,
      developerInfo: data
    }))
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const readAllEdgeConfigItems = await fetch(
          process.env.REACT_APP_USER_INFO_URL
        )
        const { items } = await readAllEdgeConfigItems.json()
        const themeData = items['darkTheme'] // await get('darkTheme')
        toggleTheme(themeData ?? null)

        const classicHeaderData = items['classicHeader'] //await get('classicHeader')
        toggleHeader(classicHeaderData ?? null)

        const developerInfoData = items['developerInfo'] // await get('developerInfo')
        updateUserInfo(developerInfoData ?? null)

        toggleIsLoading(!Object.keys(developerInfoData).length)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [process.env.REACT_APP_USER_INFO_URL])

  const contextValueForProvider = useMemo(() => {
    return {
      value: contextValue,
      toggleTheme,
      toggleHeader,
      handleNavClick
    }
  }, [contextValue, toggleTheme, toggleHeader, handleNavClick])

  return (
    <AppContext.Provider value={contextValueForProvider}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
