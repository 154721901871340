import React, { useEffect, useRef, useState } from 'react'
import Isotope from 'isotope-layout'
import ProjectDetailsModal from './ProjectDetailsModal'
import useAppContext from '../hooks/useAppContext'
import SectionTitle from './SectionTitle'

const Portfolio = () => {
  const { classicHeader, darkTheme, developerInfo } = useAppContext()
  // init one ref to store the future isotope object
  const isotope = useRef()
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*')
  const [imagesLoaded, setimagesLoaded] = useState(0)
  const [selectedProjectDetails, setSelectedProjectDetails] = useState()
  const {
    portfolio: { filters, projectsData, rootPath }
  } = developerInfo

  const getCombinedPath = (path) => {
    return `${rootPath}${path}`
  }

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope('.portfolio-filter', {
      itemSelector: '.filter-item',
      layoutMode: 'masonry'
    })

    // cleanup
    return () => {
      isotope.current.destroy()
    }
  }, [])

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === '*'
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` })
    }
  }, [filterKey, imagesLoaded])

  const handleFilterKeyChange = (key) => () => setFilterKey(key)

  return (
    <>
      <section
        id='portfolio'
        className={'section ' + (darkTheme ? 'bg-dark-2' : 'bg-light')}
      >
        <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
          {/* Heading */}
          <SectionTitle title='My Work' bgTitle='Portfolio' />
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              'portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 ' +
              (darkTheme ? 'nav-light' : '')
            }
          >
            <li className='nav-item'>
              <button
                className={'nav-link ' + (filterKey === '*' ? 'active' : '')}
                onClick={handleFilterKeyChange('*')}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className='nav-item' key={i}>
                <button
                  className={
                    'nav-link ' +
                    (filterKey === filters[oneKey] ? 'active' : '')
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className='portfolio popup-ajax-gallery'>
            <div className='row portfolio-filter filter-container g-4'>
              {projectsData.length > 0 &&
                projectsData.map((project, index) => {
                  if (Object.keys(project ?? {}?.length > 0)) {
                    return (
                      <div
                        className={
                          'col-sm-6 col-lg-4 filter-item ' +
                          project.categories?.join(' ')
                        }
                        key={index}
                      >
                        <div className='portfolio-box rounded'>
                          <div className='portfolio-img rounded'>
                            {project.thumbImage?.length > 0 && (
                              <img
                                onLoad={() => {
                                  setimagesLoaded(imagesLoaded + 1)
                                }}
                                className='img-fluid d-block portfolio-image'
                                src={getCombinedPath(project.thumbImage)}
                                alt='project thumb'
                              />
                            )}
                            <div className='portfolio-overlay'>
                              <a
                                className='popup-ajax stretched-link'
                                href=''
                                onClick={() => {
                                  setSelectedProjectDetails(project)
                                }}
                                data-bs-toggle='modal'
                                data-bs-target='#exampleModal'
                              />
                              <div className='portfolio-overlay-details'>
                                <h5 className='text-white fw-400'>
                                  {project.title}
                                </h5>
                                <span className='text-light'>
                                  {project.categories?.join(', ')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
            </div>
          </div>
        </div>
      </section>
      <div className='project-details-modal'>
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          getCombinedPath={getCombinedPath}
        ></ProjectDetailsModal>
      </div>
    </>
  )
}

export default Portfolio
