import React, { useMemo } from 'react'
import useAppContext from '../hooks/useAppContext'
const TermsAndConditions = () => {
  const { darkTheme, developerInfo } = useAppContext()
  const {
    header: { name },
    contact: { email }
  } = developerInfo

  const websiteName = useMemo(() => {
    return window.location.origin
  }, [window.location.origin])

  const terms = useMemo(() => {
    return [
      {
        title: 'Use of Content',
        content: `All content on this personal portfolio website, including text, images, graphics, and multimedia elements, is the property of ${name} and is protected by intellectual property laws. You may view, download, and print the content for personal, non-commercial use only. Any unauthorized use, including but not limited to reproduction, distribution, or modification of the content, is strictly prohibited.`
      },
      {
        title: 'User Conduct',
        content:
          'When using this website, you agree to comply with all applicable laws and regulations. You shall not engage in any activities that may disrupt or interfere with the functioning of the website or infringe upon the rights of other users. You shall not attempt to gain unauthorized access to any part of the website or its underlying systems.'
      },
      {
        title: 'Privacy',
        content:
          'We respect your privacy and handle your personal information in accordance with our Privacy Policy. By using this website, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.'
      },
      {
        title: 'Third-Party Links',
        content: `This website may contain links to third-party websites or resources. These links are provided for your convenience and do not signify endorsement or responsibility for the content, accuracy, or privacy practices of those third-party websites. You acknowledge and agree that ${name} shall not be held liable for any damages or losses incurred as a result of accessing or using any third-party websites.`
      },
      {
        title: 'Limitation of Liability',
        content: `${name} shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use or inability to use this website or its content. This includes but is not limited to damages for loss of profits, data, or other intangible losses.`
      },
      {
        title: 'Modifications',
        content: `${name} reserves the right to modify, update, or remove any content, features, or functionality of this website without prior notice. We may also revise these Terms of Use at any time by updating this page. By continuing to use the website after any modifications or revisions, you accept and agree to be bound by the updated Terms of Use.`
      },
      {
        title: 'Governing Law',
        content:
          'These Terms of Use shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.'
      }
    ]
  }, [name])

  const policy = useMemo(() => {
    return [
      {
        title: 'Information Collection',
        content:
          'We may collect personal information, such as your name, email address, and any other information you voluntarily provide to us through contact forms or email communication. We may also collect non-personal information, such as your IP address and browsing behavior, through the use of cookies or similar technologies.',
        list: []
      },
      {
        title: 'Use of Information',
        content: 'We use the collected information for the following purposes',
        list: [
          'To respond to your inquiries and provide the requested services.',
          'To improve our website and customize your experience.',
          'To send periodic emails or newsletters, if you have subscribed to them.',
          'To analyze website usage and trends.'
        ]
      },
      {
        title: 'Information Sharing',
        content:
          'We do not sell, trade, or transfer your personal information to third parties without your explicit consent, except in the following circumstances',
        list: [
          'When required by law or to comply with legal processes.',
          'To enforce our website policies or protect our rights, property, or safety.',
          'To trusted third-party service providers who assist us in operating our website and conducting our business, subject to their confidentiality obligations.'
        ]
      },
      {
        title: 'Data Security',
        content:
          'We take reasonable measures to protect your personal information from unauthorized access, disclosure, or alteration. However, please note that no method of data transmission over the internet or electronic storage is completely secure.',
        list: []
      },
      {
        title: 'External Links',
        content:
          'Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of those websites. We encourage you to review the privacy policies of any third-party websites you visit.',
        list: []
      },
      {
        title: 'Children`s Privacy',
        content:
          'Our website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we may have collected information from a child under 13, please contact immediately, and we will take appropriate steps to remove the information.',
        list: []
      },
      {
        title: 'Updates to Privacy Policy',
        content:
          'We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on our website. It is your responsibility to review this Privacy Policy periodically.',
        list: []
      },
      {
        title: 'Contact Information',
        content: `If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact at ${email}.`,
        list: []
      }
    ]
  }, [])
  return (
    <div
      id='terms-policy'
      className='modal fade'
      role='dialog'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-lg modal-dialog-centered'
        role='document'
      >
        <div
          className={
            'modal-content ' + (darkTheme ? 'bg-dark-2 text-light' : '')
          }
        >
          <div className='modal-header'>
            <h5 className={'modal-title ' + (darkTheme ? 'text-white' : '')}>
              Terms &amp; Policy
            </h5>
            <button
              type='button'
              className={'btn-close ' + (darkTheme ? 'btn-close-white' : '')}
              data-bs-dismiss='modal'
              aria-label='Close'
            />
          </div>
          <div className='modal-body p-4'>
            <p>
              Welcome to {websiteName}. By accessing and using this website, you
              accept and agree to comply with the following terms and
              conditions:
            </p>
            <h3
              className={
                'mb-3 mt-4 mt-4 text-primary ' + (darkTheme ? 'text-white' : '')
              }
            >
              Terms of Use
            </h3>
            <p>
              Please read these Terms of Use carefully before using{' '}
              {websiteName}. By accessing and using this website, you agree to
              comply with these Terms of Use.
            </p>
            {terms.length > 0 &&
              terms.map((value, index) => {
                return (
                  <>
                    <h5
                      className={
                        'text-4 mt-4 fw-500 ' + (darkTheme ? 'text-white' : '')
                      }
                    >
                      {value.title}
                    </h5>
                    <p> {value.content}</p>
                  </>
                )
              })}
            <p>
              If you have any questions or concerns regarding the Terms of Use,
              please contact {name} at {email}.
            </p>
            <p>
              By using this website, you agree to abide by these Terms of Use.
              If you do not agree with any of these terms, please refrain from
              using this website.
            </p>
            <h3
              className={
                'mb-3 mt-4 text-primary ' + (darkTheme ? 'text-white' : '')
              }
            >
              Privacy Policy
            </h3>
            <p>
              At {websiteName}, we respect your privacy and are committed to
              protecting your personal information. This Privacy Policy outlines
              how we collect, use, and safeguard the information you provide
              when accessing or using our personal portfolio website.
            </p>
            {policy.length > 0 &&
              policy.map(({ title, content, list }) => {
                return (
                  <>
                    <h5
                      className={
                        'text-4 mt-4 fw-500 ' + (darkTheme ? 'text-white' : '')
                      }
                    >
                      {title}
                    </h5>
                    <p> {content}</p>
                    {list?.length > 0 && (
                      <ul>
                        {list.map((listItem) => {
                          return <li>{listItem}</li>
                        })}
                      </ul>
                    )}
                  </>
                )
              })}
            <p>
              By using our website, you consent to the terms of this Privacy
              Policy. If you do not agree with this policy, please refrain from
              using our website.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
