import React, { useState, useMemo } from 'react'
import { Link } from 'react-scroll'
import SocialIcons from './SocialIcons'
import useAppContext from '../hooks/useAppContext'

const ClassicHeader = () => {
  const { darkTheme, developerInfo } = useAppContext()
  const {
    header: { name }
  } = developerInfo
  const [stickyHeader, setStickyHeader] = useState(false)
  const [isNavModalClose, setIsNavModalClose] = useState(true)

  const checkScrollTop = () => {
    let header = document.getElementsByClassName('primary-menu')

    if (header) {
      if (
        document.body.scrollTop > 180 ||
        document.documentElement.scrollTop > 180
      ) {
        setStickyHeader(true)
      } else {
        setStickyHeader(false)
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', checkScrollTop)
  }

  const menuItems = useMemo(() => {
    return [
      {
        title: 'Home',
        to: 'home'
      },
      {
        title: 'About Me',
        to: 'about'
      },
      {
        title: 'What I Do',
        to: 'services'
      },
      {
        title: 'Resume',
        to: 'resume'
      },
      {
        title: 'Portfolio',
        to: 'portfolio'
      },
      {
        title: 'Testimonial',
        to: 'testimonial'
      },
      {
        title: 'Contact',
        to: 'contact'
      }
    ]
  }, [])

  return (
    <header id='header' className='sticky-top-slide'>
      {/* Navbar */}
      <nav
        className={
          'primary-menu navbar navbar-expand-lg navbar-dark bg-transparent border-bottom-0 sticky-top ' +
          (stickyHeader ? 'sticky-on' : '')
        }
      >
        <div className='container-fluid position-relative g-lg-4'>
          <div className='col-auto col-lg-2'>
            {/* Logo */}
            <Link
              smooth
              duration={500}
              style={{ cursor: 'pointer' }}
              className='logo'
              to='home'
              title={name}
              onClick={(e) => {
                e.preventDefault()
                setIsNavModalClose(true)
              }}
            >
              {name?.length > 0 && (
                <h1 className='text-5 text-white text-center mb-0 d-lg-block'>
                  {name}
                </h1>
              )}
            </Link>
            {/* Logo End */}
          </div>
          <div className='col col-lg-8 navbar-accordion'>
            <button
              onClick={(e) => {
                setIsNavModalClose(!isNavModalClose)
              }}
              className={
                isNavModalClose
                  ? 'navbar-toggler ms-auto'
                  : 'navbar-toggler ms-auto show'
              }
              id='navbar-toggler'
              type='button'
            >
              <span />
              <span />
              <span />
            </button>
            <div
              id='header-nav'
              className={
                isNavModalClose
                  ? 'collapse navbar-collapse justify-content-center '
                  : 'show navbar-collapse justify-content-center'
              }
            >
              <ul className='navbar-nav'>
                {menuItems.map((menuItem) => {
                  return (
                    <li className='nav-item'>
                      <Link
                        smooth
                        duration={500}
                        style={{ cursor: 'pointer' }}
                        spy
                        activeClass='active'
                        className='nav-link'
                        to={menuItem.to}
                        onClick={(e) => {
                          e.preventDefault()
                          setIsNavModalClose(true)
                        }}
                      >
                        {menuItem.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className='col-auto col-lg-2 d-flex justify-content-end'>
            <SocialIcons darkTheme={darkTheme} />
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  )
}

export default ClassicHeader
