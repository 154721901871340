import React from 'react'
import useAppContext from '../hooks/useAppContext'
import SectionTitle from './SectionTitle'
const Services = () => {
  const { classicHeader, darkTheme, developerInfo } = useAppContext()
  const { services } = developerInfo
  return (
    <section
      id='services'
      className={'section ' + (darkTheme ? 'bg-dark-2' : 'bg-light')}
    >
      <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
        {/* Heading */}
        <SectionTitle title='What I Do?' bgTitle='Services' />
        {/* Heading end*/}
        {/* content start */}
        <div className='row'>
          <div className='col-lg-11 mx-auto'>
            <div className='row'>
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className='col-md-6' key={index}>
                    <div className='featured-box style-3 mb-5'>
                      <div
                        className={
                          'featured-box-icon text-primary  shadow-sm rounded ' +
                          (darkTheme ? 'bg-dark-1' : 'bg-white')
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? 'text-white' : ''}>
                        {service.name}
                      </h3>
                      <p
                        className={'mb-0 ' + (darkTheme ? 'text-white-50' : '')}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  )
}

export default Services
