import './App.scss'
import { useState } from 'react'
import {
  Header,
  Home,
  About,
  Services,
  Resume,
  Portfolio,
  Testimonials,
  Contact,
  Footer,
  ClassicHeader,
  TermsAndConditions,
  Disclaimer,
  Preloader,
  Tooltip
} from './components'
import useAppContext from './hooks/useAppContext'

const App = () => {
  const { classicHeader, isLoading } = useAppContext()
  const [scrollTopVisible, setScrollTopVisible] = useState(false)

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById('back-to-top')

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true)
      } else {
        setScrollTopVisible(false)
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', checkScrollTop)
  }

  return (
    <div
      style={{ position: 'relative' }}
      className={classicHeader ? '' : 'side-header'}
    >
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div id='main-wrapper'>
            {classicHeader ? <ClassicHeader /> : <Header />}
            <div id='content' role='main'>
              <Home />
              <About />
              <Services />
              <Resume />
              <Portfolio />
              <Testimonials />
              <Contact />
            </div>
            <Footer />
          </div>
          {/* back to top */}
          <Tooltip text='Back to Top' placement='left'>
            <span
              id='back-to-top'
              className='rounded-circle'
              style={{ display: scrollTopVisible ? 'inline' : 'none' }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
              <i className='fa fa-chevron-up'></i>
            </span>
          </Tooltip>
          <TermsAndConditions />
          <Disclaimer />
        </>
      )}
    </div>
  )
}

export default App
