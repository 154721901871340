import React, { useCallback, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SocialIcons from './SocialIcons'
import useAppContext from '../hooks/useAppContext'
import SectionTitle from './SectionTitle'

const Contact = () => {
  const { classicHeader, darkTheme, developerInfo } = useAppContext()
  const form = useRef()
  const [sendingMail, setSendingMail] = useState(false)
  const {
    contact: { serviceID, templateID, publicKey, address, phone, mobile, email }
  } = developerInfo

  const sendEmail = useCallback(
    (e) => {
      if (!publicKey || !serviceID || !templateID) {
        e.preventDefault()
        setSendingMail(false)
        toast.error('Something went wrong! Please try again later', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkTheme ? 'dark' : 'light'
        })
        return
      }

      e.preventDefault()
      setSendingMail(true)
      emailjs.sendForm(serviceID, templateID, form.current, publicKey).then(
        (result) => {
          document.getElementById('contact-form').reset()
          toast.success('Message sent successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? 'dark' : 'light'
          })
          console.log(result.text)
          setSendingMail(false)
        },
        (error) => {
          toast.error('Something went wrong!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? 'dark' : 'light'
          })
          console.log(error.text)
          setSendingMail(false)
        }
      )
    },
    [darkTheme, publicKey, serviceID, templateID]
  )

  return (
    <section
      id='contact'
      className={'section ' + (darkTheme ? 'bg-dark-2' : 'bg-light')}
    >
      <div className={'container ' + (classicHeader ? '' : 'px-lg-5')}>
        {/* Heading */}
        <SectionTitle title='Get in Touch' bgTitle='Contact' />
        {/* Heading end*/}
        <div className='row gy-5'>
          {/* contact details */}
          <div className='col-md-4 col-xl-3 order-1 order-md-0 text-center text-md-start'>
            {Object.keys(address ?? {})?.length > 0 && (
              <>
                <h2
                  className={
                    'mb-3 text-5 text-uppercase ' +
                    (darkTheme ? 'text-white' : '')
                  }
                >
                  Address
                </h2>
                <p className={'text-3 mb-4 ' + (darkTheme ? 'text-light' : '')}>
                  {address.line1}
                  <br />
                  {address.line2}
                  <br />
                  {address.line3}
                </p>
              </>
            )}
            {phone?.length > 0 && (
              <p className={'text-3 mb-1 ' + (darkTheme ? 'text-light' : '')}>
                <span className='text-primary text-4 me-2'>
                  <i className='fas fa-phone' />
                </span>
                {phone}
              </p>
            )}
            {mobile?.length > 0 && (
              <p className={'text-3 mb-1 ' + (darkTheme ? 'text-light' : '')}>
                <span className='text-primary text-4 me-2'>
                  <i className='fas fa-fax' />
                </span>
                {mobile}
              </p>
            )}
            {email?.length > 0 && (
              <p className={'text-3 mb-4 ' + (darkTheme ? 'text-light' : '')}>
                <span className='text-primary text-4 me-2'>
                  <i className='fas fa-envelope' />
                </span>
                {email}
              </p>
            )}
            <h2
              className={
                'mb-3 text-5 text-uppercase ' + (darkTheme ? 'text-white' : '')
              }
            >
              Follow Me
            </h2>
            <SocialIcons darkTheme={darkTheme} />
          </div>
          {/* contact form */}
          <div className='col-md-8 col-xl-9 order-0 order-md-1'>
            <h2
              className={
                'mb-3 text-5 text-uppercase text-center text-md-start ' +
                (darkTheme ? 'text-white' : '')
              }
            >
              Send us a note
            </h2>
            <form
              className={darkTheme ? 'form-dark' : ''}
              id='contact-form'
              action='php/mail.php'
              method='post'
              ref={form}
              onSubmit={sendEmail}
            >
              <div className='row g-4'>
                <div className='col-xl-6'>
                  <input
                    name='user_name'
                    type='text'
                    className='form-control'
                    required
                    placeholder='Name'
                  />
                </div>
                <div className='col-xl-6'>
                  <input
                    name='user_email'
                    type='email'
                    className='form-control'
                    required
                    placeholder='Email'
                  />
                </div>
                <div className='col'>
                  <textarea
                    name='message'
                    className='form-control'
                    rows={5}
                    required
                    placeholder='Tell us more about your needs........'
                    defaultValue={''}
                  />
                </div>
              </div>
              <p className='text-center mt-4 mb-0'>
                <button
                  id='submit-btn'
                  className='btn btn-primary rounded-pill d-inline-flex'
                  type='submit'
                  // disabled={!publicKey || !serviceID || !templateID}
                >
                  {sendingMail ? (
                    <>
                      <span
                        role='status'
                        aria-hidden='true'
                        class='spinner-border spinner-border-sm align-self-center me-2'
                      ></span>
                      Sending.....
                    </>
                  ) : (
                    <>Send Message</>
                  )}
                </button>
              </p>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
