import { useContext } from 'react'
import { AppContext } from '../context/AppContext'

const useAppContext = () => {
  const {
    value: { darkTheme, classicHeader, isLoading, developerInfo },
    toggleTheme,
    toggleHeader,
    handleNavClick
  } = useContext(AppContext)

  return {
    darkTheme,
    classicHeader,
    isLoading,
    toggleTheme,
    toggleHeader,
    handleNavClick,
    developerInfo
  }
}

export default useAppContext
